import React from 'react';
import { FaMusic, FaShare, FaDownload, FaStar, FaMagic, FaHeart } from 'react-icons/fa';

const MarketingPage = ({ onGetStarted }) => {
  const exampleSongUrl = process.env.PUBLIC_URL + "/client/birthday-song.mp3";

  return (
    <div className="min-h-screen bg-gradient-to-br from-birthday-pink via-birthday-blue to-birthday-yellow text-white relative overflow-hidden">
      <div className="container mx-auto px-4 py-12 relative z-10">
        {/* Social Proof Banner */}
        <div className="text-center mb-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg py-2">
          <p className="text-sm">
            <span className="font-bold">🎵 Over 10,000 songs created</span> • 
            <span className="mx-2">⭐️ 4.9/5 from 2,500+ reviews</span> • 
            <span className="animate-pulse">🔥 Limited Time: Only $0.99!</span>
          </p>
        </div>

        {/* Hero Section */}
        <div className="flex flex-col lg:flex-row items-center justify-between mb-16">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 leading-tight">
              Transform Your Story Into a Musical Masterpiece
            </h1>
            <p className="text-xl mb-4 opacity-90">
              Create personalized AI-generated songs that capture your unique essence in minutes. Perfect for birthdays, special occasions, or just because!
            </p>
            {/* Value Proposition */}
            <div className="mb-6 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-4">
              <p className="text-lg font-bold mb-2">✨ What You'll Get:</p>
              <ul className="list-none space-y-2">
                <li className="flex items-center"><span className="text-birthday-yellow mr-2">✓</span> Professional studio-quality song ($500+ value)</li>
                <li className="flex items-center"><span className="text-birthday-yellow mr-2">✓</span> Custom lyrics written just for you</li>
                <li className="flex items-center"><span className="text-birthday-yellow mr-2">✓</span> Unlimited downloads & sharing</li>
              </ul>
            </div>
            <button
              onClick={onGetStarted}
              className="bg-birthday-yellow text-gray-800 px-8 py-4 rounded-full text-xl font-bold hover:bg-white transition-colors duration-300 shadow-lg relative overflow-hidden group"
            >
              <span className="absolute inset-0 bg-white opacity-0 group-hover:opacity-20 transition-opacity duration-300"></span>
              Create Your SongSelfie Now
              <span className="block text-sm">Limited Time: Only $0.99!</span>
            </button>
          </div>
          <div className="lg:w-1/2 relative">
            <div className="rounded-2xl overflow-hidden shadow-2xl max-w-md mx-auto">
              <div className="aspect-w-4 aspect-h-3">
                <img 
                  src={process.env.PUBLIC_URL + "/girl-with-headphones.jpg"} 
                  alt="Girl enjoying music with headphones" 
                  className="w-full h-full object-cover object-center transform scale-90 hover:scale-95 transition-transform duration-300"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Demo Section */}
        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-8 mb-16">
          <h2 className="text-2xl font-bold text-center mb-6">Experience the Magic</h2>
          <div className="max-w-2xl mx-auto">
            <audio controls src={exampleSongUrl} className="w-full mb-4" />
            <p className="text-center text-lg opacity-90">
              Listen to this example SongSelfie and imagine your own personalized musical creation!
            </p>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          <FeatureCard
            icon={<FaMagic />}
            title="AI-Powered Creation"
            description="Advanced AI technology transforms your story into unique, professional-quality songs"
          />
          <FeatureCard
            icon={<FaMusic />}
            title="Multiple Genres"
            description="Choose from a wide variety of musical styles to match your personality"
          />
          <FeatureCard
            icon={<FaHeart />}
            title="Personalized Lyrics"
            description="Custom-written lyrics that reflect your unique traits and story"
          />
          <FeatureCard
            icon={<FaStar />}
            title="Studio Quality"
            description="High-fidelity audio production that sounds professionally made"
          />
          <FeatureCard
            icon={<FaShare />}
            title="Easy Sharing"
            description="Share your musical creation instantly on all major social platforms"
          />
          <FeatureCard
            icon={<FaDownload />}
            title="Download & Keep"
            description="Download your SongSelfie in high-quality format to keep forever"
          />
        </div>

        {/* Add Testimonials before final CTA */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          {[
            { name: "Sarah M.", text: "The perfect birthday surprise! Everyone loved it!" },
            { name: "Mike R.", text: "Can't believe how professional it sounds. Best $0.99 ever spent!" },
            { name: "Lisa K.", text: "Made my friend cry (happy tears). Will definitely use again!" }
          ].map((testimonial, index) => (
            <div key={index} className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-xl">
              <div className="flex items-center mb-2">
                {[1,2,3,4,5].map(star => (
                  <FaStar key={star} className="text-birthday-yellow text-sm" />
                ))}
              </div>
              <p className="italic mb-2">"{testimonial.text}"</p>
              <p className="font-bold">- {testimonial.name}</p>
            </div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Create Your Own SongSelfie?</h2>
          <p className="text-xl mb-8 opacity-90">
            Join thousands of happy users who have already created their musical memories!
          </p>
          <button
            onClick={onGetStarted}
            className="bg-birthday-yellow text-gray-800 px-8 py-4 rounded-full text-xl font-bold hover:bg-white transition-colors duration-300 shadow-lg"
          >
            Get Started for Just $0.99
          </button>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-6 rounded-xl hover:transform hover:scale-105 transition-transform duration-300">
    <div className="text-3xl mb-4 text-birthday-yellow">
      {icon}
    </div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="opacity-90">{description}</p>
  </div>
);

export default MarketingPage;